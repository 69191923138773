// i18n
import { i18n } from '@ecg-marktplaats/js-react-i18n';
import dateLocale from 'date-fns/locale/fr';
import translation from '../../../../locales/fr-BE/translation.json';
import common from '../../../../locales/fr-BE/common.json';
import priceTypes from '../../../../locales/fr-BE/priceTypes.json';

export default i18n.init({
  lng: 'fr-BE',
  translations: {
    translation,
    common,
    priceTypes,
  },
  dateLocale,
});
